<template>
  <div>
    <Title title="Resumen de recibos para timbrar" />
    <Layout>
      <Column>
        <table class="table-confirm">
          <tbody>
            <tr>
              <td>Num. Colaboradores</td>
              <td>{{ summaryProcess.totalEmployees }}</td>
            </tr>
            <tr>
              <td>Num. Nominas</td>
              <td>{{ summaryProcess.totalRecords }}</td>
            </tr>
            <tr>
              <td>Percepciones</td>
              <td>{{ summaryProcess.totalPerc }}</td>
            </tr>
            <tr>
              <td>Deducciones</td>
              <td>{{ summaryProcess.totalDeduc }}</td>
            </tr>
            <tr>
              <td>Otros a pagos</td>
              <td>{{ summaryProcess.totalOtherPayment }}</td>
            </tr>
            <tr class="net-to-pay-sync">
              <td>Neto a pagar</td>
              <td>{{ summaryProcess.totalNet }}</td>
            </tr>
          </tbody>
        </table>
      </Column>
      <Column style="margin: 12px 0">
        <CheckBox label="He verificado los totales" v-model="confirmSync" />
      </Column>
      <Column align="right">
        <Button color="terciary-outlined" @click="$parent.close()"> Cancelar </Button>
        <Button color="secondary" :disabled="!confirmSync" @click="() => $emit('startStamp')">
          Confirmar
        </Button>
      </Column>
    </Layout>
  </div>
</template>

<script>
export default {
  name: 'StampConfirmation',
  props: {
    summaryProcess: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      confirmSync: false,
    };
  },
};
</script>

<style lang="scss">
.table-confirm {
  font-size: 14px;
  width: 100%;
  .net-to-pay-sync {
    font-weight: bold;
    color: #5b89f2;
  }
}
</style>
