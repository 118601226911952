<template>
  <div class="payroll-receipts-documents">
    <span
      class="payroll-receipts-document payroll-receipts-document--pdf"
      title="Descargar PDF"
      @click="() => downloadFile('FilePDF')"
    >
      <i class="mdi mdi-file-pdf-outline"></i>
    </span>
    <span
      class="payroll-receipts-document payroll-receipts-document--xml"
      title="Descargar XML"
      @click="() => downloadFile('FileXML')"
    >
      <i class="mdi mdi-file-document-outline"></i>
    </span>
    <span
      v-if="receipt.cfdiStatusCode === 'CANCELLED'"
      class="payroll-receipts-document payroll-receipts-document--cancel"
      title="Descargar acuse de cancelación"
      @click="() => downloadFile('FilePDF', true)"
    >
      <i class="mdi mdi-file-cancel-outline"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PayrollReceiptDocuments',
  props: {
    receipt: Object,
    receiptParent: Object,
  },
  methods: {
    downloadFile(fileType, isAcuse = false) {
      let query = {};

      if (!isAcuse) {
        query = {
          FileType: fileType,
          PKPayrollCFDI: this.receipt.pkPayrollCFDI,
          PayrollEmployee: this.receiptParent.timPayrollEmployeeID,
        };
      } else {
        query = {
          PayrollCFDIID: this.receipt.pkPayrollCFDI,
          PayrollEmployeeID: this.receiptParent.timPayrollEmployeeID,
        };
      }

      const fileTypes = {
        FileXML: 'data:application/xml;base64,',
        FilePDF: 'data:application/pdf;base64,',
      };

      const endpoint = !isAcuse ? 'storage/DownloadFile' : 'storage/AcuseCancellation';

      this.$stream(endpoint, query, 'stamp').then(({ data }) => {
        const link = document.createElement('a');
        link.href = `${fileTypes[fileType]}${data.File64}`;
        link.setAttribute('download', data.FileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
  },
};
</script>

<style lang="scss">
.payroll-receipts-document {
  font-size: 22px;
  cursor: pointer;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  &--pdf {
    color: #e01f25;
  }
  &--xml {
    color: #2c7cd5;
  }
  &--cancel {
    color: #f25555;
  }
}
</style>
