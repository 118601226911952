<template>
  <div>
    <Frame title="Recibos previos" :titleAction="receipt.employeeFullName">
      <Grid :data="data" :showAdd="false" :actionConditional="actions" :paginated="false">
        <template slot-scope="{ row }" tag="tr">
          <GridColumn title="UUID">
            {{ row.uuid }}
          </GridColumn>
          <GridColumn title="Est">
            <PayrollReceiptStatusIcon :receipt="row" />
          </GridColumn>
          <GridColumn title="Fecha de cancelación">
            {{ row.cancellationDate }}
          </GridColumn>
          <GridColumn title="Documentos" v-if="$can('DownloadPayroll', 'STM')">
            <PayrollReceiptDocuments :receipt="row" :receiptParent="receipt" />
          </GridColumn>
        </template>
      </Grid>
      <br />
      <hr />
      <Layout>
        <Column align="right">
          <Button color="secondary" @click="$parent.close()">Aceptar</Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
import PayrollReceiptDocuments from './PayrollReceiptDocuments.vue';
import PayrollReceiptStatusIcon from './PayrollReceiptStatusIcon.vue';

export default {
  name: 'PayrollReceiptDetail',
  components: {
    PayrollReceiptDocuments,
    PayrollReceiptStatusIcon,
  },
  props: {
    receipt: Object,
  },
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.getDetailReceipt();
  },
  methods: {
    cancelCFDI(item) {
      this.showConfirm(
        `¿Deseas cancelar el recibo seleccionado?`,
        `Esto enviará al SAT, la solicitud de cancelación
        del recibo de nómina seleccionado.`,
        'danger',
        [
          {
            text: 'Salir',
            callback: () => {},
            props: { color: 'terciary-outlined' },
          },
          {
            text: `Cancelar recibo`,
            callback: () => {
              this.loading = true;
              this.$post(`stamp/org/cancelCFDI/${item.pkPayrollCFDI}`, null, 'stamp')
                .then(({ data }) => {
                  this.loading = false;
                  if (data.BatchCFDIProcessID > 0) {
                    this.setNotification(data.StampProcessMessageResult);
                    this.receiptsSelected = [];
                    this.allSelected = false;
                    this.getReceipts(this.query);
                  } else {
                    this.setNotification(data.StampProcessMessageResult, 'warning');
                  }
                })
                .catch(({ response }) => {
                  this.loading = false;
                  this.setNotification(response.data.StampProcessMessageResult, 'warning');
                });
            },
            props: { color: 'quinary-outlined' },
          },
        ]
      );
    },
    actions(item) {
      const options = [
        {
          text: 'Cancelar CFDI',
          callback: this.cancelCFDI,
        },
      ];

      if (item.stampStatusCode === 'STAMPED' && this.$can('CancelPayroll', 'STM')) {
        return options;
      }
      return [];
    },
    getDetailReceipt() {
      const query = {
        TimPayRollEmployee: this.receipt.timPayrollEmployeeID,
      };
      this.$post('Stamp/Org/ReceiptsDetail', query, 'stamp').then(({ data }) => {
        this.data = data;
      });
    },
  },
};
</script>
