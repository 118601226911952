<template>
  <Frame
    :loading="loading"
    title="Recibos"
    title-action="Timbrar recibos relacionados con UUID anteriores"
  >
    <InfoCard type="warn">
      Se detectaron recibos de nómina relacionados con una precancelación, verifícalos antes de
      continuar.
      <a href="#!" @click="downloadReceiptsPrecancel">Descargar detallado de recibos</a>
    </InfoCard>
    <br />
    <Layout>
      <Column size="6">
        <table class="table-confirm" style="margin-left: 16px">
          <tbody>
            <tr>
              <td>Recibos a enviar con sustitución</td>
              <td>{{ precancelSummary.precancelCount }}</td>
            </tr>
            <tr>
              <td>Recibos a enviar sin sustitución</td>
              <td>{{ precancelSummary.cancelledCount }}</td>
            </tr>
          </tbody>
        </table>
      </Column>
    </Layout>
    <br />
    <hr />
    <Layout>
      <Column align="right">
        <Button color="terciary-outlined" @click="$parent.close()"> Cancelar </Button>
        <Button color="secondary" @click="() => $emit('confirm')"> Confirmar timbrado </Button>
      </Column>
    </Layout>
  </Frame>
</template>

<script>
export default {
  name: 'StampPrecancelConfirmation',
  props: {
    precancelSummary: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    downloadReceiptsPrecancel() {
      this.loading = true;
      this.$stream('stamp/org/GetPrecancelDetail', this.precancelSummary.query, 'stamp', 'POST', {
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.loading = false;
          const { data, headers } = response;
          const disposition = headers['content-disposition'];
          let filename = '';

          if (disposition && disposition.indexOf('attachment') !== -1) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
          const blob = new Blob([data], { type: headers['content-type'] });
          const FileDownload = require('js-file-download');
          FileDownload(blob, filename);
        })
        .catch(() => {
          this.loading = false;
          this.setNotification('Ocurrió un error al descargar el archivo', 'warning');
        });
    },
  },
};
</script>
