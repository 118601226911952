<template>
  <div class="payroll-receipts-filter">
    <Layout>
      <Column size="3">
        <Field label="Ejercicio - Mes">
          <MultiSelect
            :options="catalogs.exercicemonth"
            :multiple="true"
            v-model="periodAndMonths"
          />
        </Field>
      </Column>
      <Column size="3">
        <Field label="Periodicidad de pago">
          <MultiSelect
            :options="catalogs.paymentPeriodicity"
            :multiple="true"
            :defaultExpandLevel="1"
            v-model="query.PaymentPeriodicity"
          />
        </Field>
      </Column>
      <Column size="3">
        <Field label="Periodo">
          <MultiSelect
            :options="catalogs.PaymentPeriod"
            :multiple="true"
            :defaultExpandLevel="1"
            v-model="query.PaymentPeriod"
          />
        </Field>
      </Column>
      <Column size="3">
        <Field label="Unidad de negocio">
          <MultiSelect
            :options="catalogs.Locations"
            :multiple="true"
            :defaultExpandLevel="1"
            v-model="query.Locations"
          />
        </Field>
      </Column>

      <Column size="3">
        <Field label="Razón Social - Registro Patronal">
          <MultiSelect
            :options="catalogs.Companies"
            :multiple="true"
            :defaultExpandLevel="1"
            :load-options="loadRegistroPatronal"
            v-model="CompaniesEmployerRegistrations"
            @open="openCompanies"
            @close="closeCompanies"
          />
        </Field>
      </Column>
      <Column size="3">
        <Field label="Esquema">
          <MultiSelect
            :options="catalogs.paymentSchema"
            :multiple="true"
            :defaultExpandLevel="1"
            v-model="query.PayrollSchema"
          />
        </Field>
      </Column>
      <Column size="3">
        <Field label="Estatus del timbre">
          <MultiSelect
            :options="catalogs.statusCFDI"
            :multiple="true"
            :defaultExpandLevel="1"
            v-model="query.StatusCFDI"
          />
        </Field>
      </Column>
      <Column size="3">
        <Field label="Estatus notificación">
          <MultiSelect
            :options="catalogs.statusMail"
            :multiple="true"
            :defaultExpandLevel="1"
            v-model="query.StatusMail"
          />
        </Field>
      </Column>

      <Column size="3">
        <Field label="ID de colaborador o RFC">
          <Input v-model="query.Employees" @keypress.enter="executeQuery" />
        </Field>
      </Column>
      <Column size="6">
        <Layout v-show="showDetailedFilters">
          <Column size="4">
            <Field label="Departamento">
              <MultiSelect
                :options="catalogs.departments"
                :multiple="true"
                :defaultExpandLevel="1"
                v-model="query.departments"
              />
            </Field>
          </Column>
          <Column size="4">
            <Field label="Puesto">
              <MultiSelect
                :options="catalogs.jobs"
                :multiple="true"
                :defaultExpandLevel="1"
                v-model="query.jobpositions"
              />
            </Field>
          </Column>
        </Layout>
      </Column>
      <Column size="3">
        <div class="buttons-filter">
          <Button icon="filter-remove-outline" color="secondary-outlined" @click="resetFilters" />
          <Button
            title="Más filtros"
            icon="tune"
            color="secondary-outlined"
            @click="changeShowDetailedFilters"
          />
          <Button color="secondary" @click="executeQuery"> Consultar registros </Button>
        </div>
      </Column>
    </Layout>
  </div>
</template>

<script>
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect';

export default {
  name: 'PayrollReceiptsFilter',
  mounted() {
    this.getCatalogs();
    this.getPaymentPeriods();
    this.getBusinessUnit();
    this.getCompanies();
    this.getRegistrations();
  },
  data() {
    return {
      catalogs: {
        month: [],
        paymentPeriodicity: [],
        paymentSchema: [],
        statusCFDI: [],
        statusMail: [],
        year: [],
        Companies: [
          {
            id: '',
            label: 'Todos',
            children: [],
          },
        ],
        Locations: [
          {
            id: '',
            label: 'Todos',
            children: [],
          },
        ],
        EmployerRegistrations: [
          {
            id: '',
            label: 'Todos',
            children: [],
          },
        ],
        PaymentPeriod: [
          {
            id: '',
            label: 'Todos',
            children: [],
          },
        ],
        divisions: [
          {
            id: '',
            label: 'Todos',
            children: [],
          },
        ],
        departments: [
          {
            id: '',
            label: 'Todos',
            children: [],
          },
        ],
        jobs: [
          {
            id: '',
            label: 'Todos',
            children: [],
          },
        ],
      },
      query: {
        CumulativeYear: new Date().getFullYear(),
        CumulativeMonths: [new Date().getMonth() + 1],
        PaymentPeriodicity: [''],
        Companies: [''],
        Employees: null,
        EmployerRegistrations: [''],
        Locations: [''],
        PaymentPeriod: [''],
        PayrollSchema: [''],
        StatusCFDI: [],
        StatusMail: [''],
        departments: [''],
        jobpositions: [''],
      },
      showDetailedFilters: false,
      CompaniesEmployerRegistrations: [''],
    };
  },
  methods: {
    openCompanies() {
      if (this.CompaniesEmployerRegistrations[0] === '') {
        this.CompaniesEmployerRegistrations = [];
      }
    },
    closeCompanies() {
      if (this.CompaniesEmployerRegistrations.length === 0) {
        this.CompaniesEmployerRegistrations = [''];
      }
    },
    async loadRegistroPatronal({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        const regs = await this.getRegistrationsByCompany(parentNode.id);
        parentNode.children = regs;
        callback();
      }
    },
    async getRegistrationsByCompany(Company) {
      const query = {
        Companies: [Company],
        CumulativeMonths: this.query.CumulativeMonths.filter((x) => x !== ''),
        CumulativeYear: this.query.CumulativeYear,
        PaymentPeriodicity: this.query.PaymentPeriodicity.filter((x) => x !== ''),
        PaymentPeriod: this.query.PaymentPeriod.filter((x) => x !== ''),
      };
      return this.$post('stamp/org/Catalogs/EmployerRegistration', query, 'stamp').then(
        ({ data }) => {
          this.query.EmployerRegistrations = [''];
          return data.employerRegCat.map((x) => {
            return {
              id: `${Company}|${x.employerRegistrationID}`,
              label: x.employeeRegistration,
            };
          });
        }
      );
    },
    changeShowDetailedFilters() {
      this.showDetailedFilters = !this.showDetailedFilters;
    },
    resetFilters() {
      this.query = {
        CumulativeYear: new Date().getFullYear(),
        CumulativeMonths: [new Date().getMonth() + 1],
        PaymentPeriodicity: [''],
        Companies: [''],
        Employees: null,
        EmployerRegistrations: [''],
        Locations: [''],
        PaymentPeriod: [''],
        PayrollSchema: [''],
        departments: [''],
        jobpositions: [''],
        StatusMail: [''],
        StatusCFDI: this.catalogs.statusCFDI[0].children
          .filter((x) => x.IsSelectedByDefault)
          .map((x) => x.id),
      };
    },
    getPaymentPeriods() {
      const query = {
        CumulativeMonths: this.query.CumulativeMonths.filter((x) => x !== ''),
        CumulativeYear: this.query.CumulativeYear,
        PaymentPeriodicity: this.query.PaymentPeriodicity.filter((x) => x !== ''),
      };

      this.$post('stamp/org/catalogs/periods', query, 'stamp').then(({ data }) => {
        this.query.PaymentPeriod = [''];
        this.getBusinessUnit();
        this.getCompanies();

        this.catalogs.PaymentPeriod = [
          {
            id: '',
            label: 'Todos',
            children: data.paymentPeriods.map((x) => {
              return {
                id: x.paymentPeriodID,
                label: x.paymentPeriodDescription,
              };
            }),
          },
        ];
      });
    },
    getBusinessUnit() {
      const query = {
        CumulativeMonths: this.query.CumulativeMonths.filter((x) => x !== ''),
        CumulativeYear: this.query.CumulativeYear,
        PaymentPeriodicity: this.query.PaymentPeriodicity.filter((x) => x !== ''),
        PaymentPeriod: this.query.PaymentPeriod.filter((x) => x !== ''),
      };
      this.$post('stamp/org/catalogs/locations', query, 'stamp').then(({ data }) => {
        this.query.Locations = [''];
        this.catalogs.Locations = [
          {
            id: '',
            label: 'Todos',
            children: data.locations.map((x) => {
              return {
                id: x.locationID,
                label: x.locationDescription,
              };
            }),
          },
        ];
      });
    },
    getCompanies() {
      const query = {
        CumulativeMonths: this.query.CumulativeMonths.filter((x) => x !== ''),
        CumulativeYear: this.query.CumulativeYear,
        PaymentPeriodicity: this.query.PaymentPeriodicity.filter((x) => x !== ''),
        PaymentPeriod: this.query.PaymentPeriod.filter((x) => x !== ''),
      };
      this.$post('stamp/org/catalogs/companies', query, 'stamp').then(({ data }) => {
        this.query.Companies = [''];
        this.getRegistrations();
        this.catalogs.Companies = [
          {
            id: '',
            label: 'Todos',
            children: data.companies.map((x) => {
              return {
                id: x.companyID,
                label: x.legalName,
                children: null,
              };
            }),
          },
        ];
      });
    },
    getRegistrations() {
      const query = {
        Companies: this.query.Companies.filter((x) => x !== ''),
        CumulativeMonths: this.query.CumulativeMonths.filter((x) => x !== ''),
        CumulativeYear: this.query.CumulativeYear,
        PaymentPeriodicity: this.query.PaymentPeriodicity.filter((x) => x !== ''),
        PaymentPeriod: this.query.PaymentPeriod.filter((x) => x !== ''),
      };
      this.$post('stamp/org/Catalogs/EmployerRegistration', query, 'stamp').then(({ data }) => {
        this.query.EmployerRegistrations = [''];
        this.catalogs.EmployerRegistrations = [
          {
            id: '',
            label: 'Todos',
            children: data.employerRegCat.map((x) => {
              return {
                id: x.employerRegistrationID,
                label: x.employeeRegistration,
              };
            }),
          },
        ];
      });
    },
    isYearExpanded(year) {
      if (year === new Date().getFullYear()) {
        return true;
      }
      return false;
    },
    getCatalogs() {
      this.getAll('stamp/Org/Catalog', '', 'stamp').then((data) => {
        const catalogs = data;
        this.catalogs.month = [{ ...data.month }];
        this.catalogs.paymentPeriodicity = [{ ...data.paymentPeriodicity }];
        this.catalogs.paymentSchema = [{ ...data.paymentSchema }];
        this.catalogs.statusCFDI = [{ ...data.statusCFDI }];
        this.catalogs.statusMail = [{ ...data.statusMail }];
        this.catalogs.departments[0].children = data.departments;
        this.catalogs.jobs[0].children = data.jobPositions;

        this.query.StatusCFDI = catalogs.statusCFDI.children
          .filter((x) => x.IsSelectedByDefault)
          .map((x) => x.id);

        this.catalogs.year = data.year.children.map((x) => {
          return {
            id: x,
            label: x,
          };
        });
        this.catalogs.exercicemonth = data.year.children.map((x) => {
          return {
            id: x,
            label: x,
            isDefaultExpanded: this.isYearExpanded(x),
            children: [
              ...data.month.children.map((y) => {
                return {
                  id: `${x}|${y.id}`,
                  label: y.label,
                };
              }),
            ],
          };
        });
      });
    },
    executeQuery() {
      const query = {};
      Object.keys(this.query).forEach((x) => {
        const isArray = this.query[x] instanceof Array;
        let value = isArray ? [] : this.query[x];
        if (isArray) {
          value = this.query[x].filter((y) => y !== '');
        }
        if (x === 'Employees') {
          value =
            this.query[x] === '' || this.query[x] === null ? [] : (this.query[x] || '').split(',');
        }
        query[x] = value;
      });
      query.CatalogPeriods = [...this.catalogs.PaymentPeriod[0].children].map((x) => {
        // x.label = x.label.split('-').slice(0, -2).join('-');
        return { ...x, label: x.label.split('-').slice(0, -2).join('-') };
      });

      this.$emit('executeQuery', query);
    },
  },
  computed: {
    periodAndMonths: {
      get: function () {
        if (this.query.CumulativeMonths.length > 0) {
          return this.query.CumulativeMonths.map((e) => {
            return `${this.query.CumulativeYear}|${e}`;
          });
        }
        return [this.query.CumulativeYear];
      },
      set: function (args) {
        try {
          this.query.CumulativeYear = Number([...args].reverse()[0].split('|')[0]);

          const r = [
            ...new Set(
              args.filter((e) => {
                if (this.query.CumulativeYear !== Number(e.split('|')[0])) {
                  return false;
                }
                return true;
              })
            ),
          ];
          this.query.CumulativeMonths = r.map((e) => {
            return Number(e.split('|')[1]);
          });
        } catch (e) {
          this.query.CumulativeYear = args[args.length - 1];
          this.query.CumulativeMonths = [];
        }
      },
    },
  },
  watch: {
    'query.CumulativeYear'(val) {
      if (val) {
        this.getPaymentPeriods();
      }
    },
    'query.CumulativeMonths'(val) {
      if (val && val.length !== 0) {
        this.getPaymentPeriods();
      }
    },
    'query.PaymentPeriodicity'(val) {
      if (val && val.length !== 0) {
        this.getPaymentPeriods();
      }
    },
    CompaniesEmployerRegistrations(params) {
      try {
        const companies = [
          ...new Set(
            params
              .filter((e) => e)
              .map((e) => {
                try {
                  if (e.toString().includes('|')) {
                    return Number(e.toString().split('|')[0]);
                  }
                  return e;
                } catch (error) {
                  return e;
                }
              })
          ),
        ];

        const employerRegistrations = [
          ...new Set(
            params
              .filter((e) => {
                if (e.toString().split('|')[1] !== '' && e.toString().split('|')[1] !== undefined) {
                  try {
                    Number(e.toString().split('|')[1]);
                    return true;
                  } catch (error) {
                    return false;
                  }
                } else {
                  return false;
                }
              })
              .map((e) => {
                return Number(e.toString().split('|')[1]);
              })
          ),
        ];

        this.query.EmployerRegistrations = employerRegistrations;
        this.query.Companies = companies;
      } catch (e) {
        this.query.EmployerRegistrations = [''];
        this.query.Companies = [''];
      }
    },
  },
};
</script>

<style lang="scss">
.payroll-receipts-filter {
  padding: 24px 24px 8px 24px;
  box-sizing: border-box;
  border: 1px solid #5186ff;
  margin-bottom: 24px;
}
.buttons-filter {
  padding-top: 24px;
  margin-bottom: 21px;
}
</style>
