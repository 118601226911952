<template>
  <div class="receipts-addons">
    <Dropdown
      class="addon-icon add-icon-dropdown"
      :class="{ 'addon-icon--success': receipt.filesAvailables }"
      :disabled="!receipt.filesAvailables"
      v-if="$can('DownloadPayroll', 'STM')"
    >
      <span
        :title="`Documentos${!receipt.filesAvailables ? ' no ' : ' '}disponibles`"
        slot="trigger"
      >
        <i class="mdi mdi-folder-download-outline"></i>
      </span>
      <DropdownItem @click="() => downLoadFile('FilePDF', receipt.timPayrollEmployeeID)">
        Descargar PDF
      </DropdownItem>
      <DropdownItem @click="() => downLoadFile('FileXML', receipt.timPayrollEmployeeID)">
        Descargar XML
      </DropdownItem>
      <DropdownItem
        v-if="receipt.cfdiStatusCode === 'CANCELLED'"
        @click="() => downLoadFile('FilePDF', receipt.timPayrollEmployeeID, true)"
      >
        Descargar A. Cancelación
      </DropdownItem>
    </Dropdown>
    <span
      :class="`addon-icon addon-icon--${receipt.mailStatusCode}`"
      @click="showCFDIEmail = true"
      :title="`CFDI ${receipt.mailStatusDescription}`"
    >
      <i class="mdi mdi-email-outline"></i>
    </span>

    <span v-if="receipt.inBlackList" :class="`addon-blacklist`" :title="'Lista negra'">
      <i class="mdi mdi-email-alert-outline"></i>
    </span>

    <span
      class="addon-count"
      @click="showModal = !showModal"
      v-if="Number(receipt.previousReceipts) > 0"
    >
      <i
        class="mdi"
        :class="
          Number(receipt.previousReceipts) < 10
            ? `mdi-numeric-${Number(receipt.previousReceipts)}-box-outline`
            : 'mdi-numeric-9-plus-box-outline'
        "
      ></i>
    </span>
    <Modal :active.sync="showModal">
      <PayrollReceiptDetail :receipt="receipt" />
    </Modal>
    <Modal :active.sync="showCFDIEmail">
      <PayrollReceiptsCFDIEmail :receipt="receipt" />
    </Modal>
  </div>
</template>

<script>
import PayrollReceiptDetail from './PayrollReceiptDetail.vue';
import PayrollReceiptsCFDIEmail from './PayrollReceiptsCFDIEmail.vue';

export default {
  name: 'PayrollReceiptsAddons',
  components: {
    PayrollReceiptDetail,
    PayrollReceiptsCFDIEmail,
  },
  props: {
    receipt: Object,
  },
  data() {
    return {
      showModal: false,
      showCFDIEmail: false,
      icons: [],
    };
  },
  methods: {
    downLoadFile(DocumentType, PayrollEmployee, isAcuse = false) {
      let query;
      if (!isAcuse) {
        query = {
          FileType: DocumentType,
          PayrollEmployee: PayrollEmployee,
        };
      } else {
        query = {
          PayrollEmployeeID: PayrollEmployee,
        };
      }

      const fileTypes = {
        FileXML: 'data:application/xml;base64,',
        FilePDF: 'data:application/pdf;base64,',
      };
      const endpoint = !isAcuse ? 'storage/DownloadFile' : 'storage/AcuseCancellation';
      this.$stream(endpoint, query, 'stamp')
        .then(({ data }) => {
          const link = document.createElement('a');
          link.href = `${fileTypes[DocumentType]}${data.File64}`;
          link.setAttribute('download', data.FileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(({ response }) => {
          this.setNotification(response.data, 'warning');
        });
    },
  },
};
</script>

<style lang="scss">
.addon-icon--untouched {
  pointer-events: none !important;
}
.addon-icon {
  color: #a5acbc;
  font-size: 22px;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  vertical-align: middle;
  &--success,
  &--SENT {
    &:after {
      background-color: #6ae1c8 !important;
    }
  }
  &--failed,
  &--FAILED,
  &--issues,
  &--ISSUES {
    &:after {
      background-color: #f25555 !important;
    }
  }
  &--delivered,
  &--DELIVERED {
    &:after {
      background-color: #19bf95 !important;
    }
  }
  &--sent,
  &--SENT {
    &:after {
      background-color: #6ae1c8 !important;
    }
  }
  &--opened,
  &--OPENED {
    &::after {
      background-color: #5186ff !important;
    }
  }
  &--bounce,
  &--BOUNCE {
    &::after {
      background-color: #ff9b43 !important;
    }
  }
  &--blocked,
  &--BLOCKED {
    &::after {
      background-color: #333333 !important;
    }
  }
  &--spam,
  &--SPAM {
    &::after {
      background-color: #ffc848 !important;
    }
  }
  &--softbounce,
  &--SOFTBOUNCE {
    &::after {
      background-color: #ff9b43 !important;
    }
  }
  &--hardbounce,
  &--hardBOUNCE {
    &::after {
      background-color: #f25555 !important;
    }
  }
  &--delivered,
  &--DELIVERED {
    &::after {
      background-color: #19bf95 !important;
    }
  }
  &:after {
    width: 8px;
    height: 8px;
    border: 2px solid white;
    background-color: #a5acbc;
    display: block;
    content: '';
    transform: translateX(50%) translateY(-25%);
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
.addon-count {
  font-size: 20px;
  font-weight: 600;
  color: #5186ff;
  line-height: 19px;
  cursor: pointer;
  vertical-align: middle;
}
.add-icon-dropdown {
  &::after {
    bottom: -5px;
  }
}
.addon-blacklist {
  color: #f25555;
  font-size: 27px;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  vertical-align: middle;
}
</style>
