<template>
  <div>
    <div style="text-align: center">Selecciona el tipo de cancelación</div>
    <br />
    <Layout align="center">
      <Column style="display: flex; justify-content: center">
        <Button color="secondary" @click="$emit('precancel')">Cancelación con sustitución</Button>
      </Column>
      <Column style="display: flex; justify-content: center; margin-top: 8px">
        <Button color="terciary-outlined" @click="$emit('cancel')"
          >Cancelación sin sustitución</Button
        >
      </Column>
    </Layout>
  </div>
</template>

<script>
export default {
  name: 'CancelChooser',
};
</script>
