<template>
  <div class="box-resume" :class="keepOpen ? 'box-resume__keep-open' : ''">
    <div class="box-resume__content">
      <p class="box-resume__item">
        Total de recibos <span>{{ summary.totalRecords }}</span>
      </p>
      <p class="box-resume__item">
        Total percepciones: <span>{{ summary.totalPerc }}</span>
      </p>
      <p class="box-resume__item">
        Total deducciones: <span>{{ summary.totalDeduc }}</span>
      </p>
      <p class="box-resume__item">
        Total otros pagos <span>{{ summary.totalOtherPayment }}</span>
      </p>
      <p class="box-resume__item box-resume__item--is-net">
        Neto <span>{{ summary.totalNet }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayrollReceiptsResume',
  props: {
    summary: Object,
    keepOpen: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.box-resume {
  background-color: #f2f8fc;
  width: 280px;
  padding: 24px;
  box-sizing: border-box;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  bottom: -16px;
  right: 0;
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  &__keep-open {
    opacity: 1;
    pointer-events: all;
  }
  &__item {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 19px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      float: right;
    }
    &--is-net {
      font-weight: 700;
      color: #294380;
    }
  }
}
</style>
