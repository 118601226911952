<template>
  <Layout>
    <Column size="1" style="margin-left: -8px">
      <span style="color: #ffc848; font-size: 20px">
        <i class="mdi mdi-information" />
      </span>
    </Column>
    <Column size="11" style="margin-left: -8px">
      <Title :title="`¿Deseas precancelar ${counter}?`" />
      <Layout>
        <Column>
          Esto marcará los recibos para que puedan ser modificados, permitirá retimbrar el recibo
          correcto y cancelará el folio actual relacionado ambos de ambos UUID ante el SAT.
        </Column>
        <Column>
          <br />
          <InfoCard type="warn">
            Los recibos seleccionados serán cancelados hasta que los nuevos recibos sean timbrados.
          </InfoCard>
        </Column>
      </Layout>
      <br />
      <Layout>
        <Column>
          <Field :label="confirmLabel">
            <Input v-model="confirmText" />
          </Field>
        </Column>
      </Layout>
      <Layout>
        <Column align="right">
          <Button color="terciary-outlined" @click="$parent.close()"> Salir </Button>
          <Button
            class="precancel-button"
            color=""
            :disabled="confirmText !== 'PRECANCELAR'"
            @click="() => $emit('confirm')"
          >
            Precancelar recibos
          </Button>
        </Column>
      </Layout>
    </Column>
  </Layout>
</template>

<script>
export default {
  name: 'CancelConfirmation',
  props: {
    receiptsCounter: Number,
  },
  data() {
    return {
      confirmLabel: 'Escribe "PRECANCELAR" para confirmar',
      confirmText: '',
    };
  },
  mounted() {},
  methods: {},
  computed: {
    counter() {
      return this.receiptsCounter === 1
        ? 'el recibo seleccionado'
        : `los ${this.receiptsCounter} recibos seleccionados`;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.precancel-button {
  background-color: #ffc848;
  color: white;
  &:disabled {
    background-color: rgba(250, 216, 140, 0.77);
  }
}
</style>
