<template>
  <div>
    <Frame title="Timbrar recibo">
      <Layout class="footer-stamp-receipts">
        <Column>
          <Field label="Fecha de pago de nómina">
            <Datepicker :open="true" v-model="stampDate" />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button color="terciary-outlined" @click="$parent.close()">Cancelar</Button>
          <Button color="secondary" :disabled="!stampDate" @click="executeAction">Aceptar</Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'PayrollReceiptsStampWithDate',
  data() {
    return {
      stampDate: null,
    };
  },
  props: {
    actionReceipts: {
      type: Function,
      default: () => {},
    },
    receiptID: {
      type: Number,
      default: null,
    },
  },
  methods: {
    executeAction() {
      this.actionReceipts.apply(null, ['T', this.stampDate], this.receiptID);
      this.$parent.close();
    },
  },
};
</script>

<style lang="scss">
.footer-stamp-receipts {
  margin-bottom: 270px;
}
</style>
