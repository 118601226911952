<template>
  <div>
    <Frame
      title="Recibos"
      :loading="loading"
      v-show="$route.name === 'payrollReceipts'"
      class="custom-frame"
    >
      <div class="sticky-tool">
        <Layout>
          <Column size="4">
            <div>
              <Button icon="filter-variant" color="secondary" @click="onClickFilter">
                Filtros
              </Button>
              <Button
                icon="sync"
                color="secondary-outlined"
                @click="onClickRefresh"
                v-if="summary && !showEmpty"
              />
              <Button
                icon="file-cancel-outline"
                color="quinary-outlined"
                @click="confirmCancelations"
                v-if="summary && summary.cancellationInProcess"
              >
                Conf. Cancelaciones
              </Button>
              <Button
                icon="file-sync-outline"
                color="secondary-outlined"
                @click="confirmCheckStatus"
                v-if="summary && !showEmpty && externalStamp"
              >
                Comprobar timbrados
              </Button>
            </div>
          </Column>
          <Column size="8" align="right">
            <div class="actions__resume">
              <transition name="fade">
                <div v-show="countSelected !== 0" class="receipts-actions">
                  <span class="receipt-count">
                    {{ countSelected }} Registro{{ countSelected > 1 ? 's' : '' }} seleccionado{{
                      countSelected > 1 ? 's' : ''
                    }}
                  </span>
                  <Button
                    icon="play"
                    class="receipt-action"
                    @click="stampReceipts"
                    v-if="$can('StampPayroll', 'STM')"
                  >
                    {{ externalStamp ? 'Enviar' : 'Timbrar' }}
                  </Button>
                  <Dropdown
                    class="receipt-show dropdown-text"
                    v-if="
                      ($can('StampPayroll', 'STM') || $can('CancelPayroll', 'STM')) && externalStamp
                    "
                  >
                    <Button slot="trigger" class="receipt-action">
                      Cambiar estatus
                      <i class="mdi mdi-chevron-down"></i>
                    </Button>
                    <template v-for="(status, index) in cfdiStatuses">
                      <DropdownItem
                        @click="() => changeStatusReceipts(status)"
                        :key="`status${index}`"
                      >
                        a {{ status.Description }}
                      </DropdownItem>
                    </template>
                  </Dropdown>
                  <Button
                    icon="cancel"
                    class="receipt-action"
                    @click="() => actionReceipts('C', null)"
                    v-if="$can('CancelPayroll', 'STM') && !externalStamp"
                  >
                    Cancelar CFDI
                  </Button>
                  <Dropdown
                    class="receipt-show dropdown-text"
                    v-if="$can('DownloadPayroll', 'STM') && !externalStamp"
                  >
                    <Button slot="trigger" icon="download" class="receipt-action">
                      Descargar
                      <i class="mdi mdi-chevron-down"></i>
                    </Button>
                    <DropdownItem @click="() => confirmDownload('FilePDF')">
                      Descargar PDF
                    </DropdownItem>
                    <DropdownItem @click="() => confirmDownload('FileXML')">
                      Descargar XML
                    </DropdownItem>
                    <DropdownItem @click="() => confirmDownload('FilePDF,FileXML')">
                      Descargar ambos
                    </DropdownItem>
                  </Dropdown>
                  <Button
                    icon="email-newsletter"
                    class="receipt-action receipt-show"
                    @click="confirmSendEmail"
                    :disabled="loadingSentReceipts"
                    v-if="$can('SendMail', 'STM') && !externalStamp"
                  >
                    Enviar por correo
                  </Button>
                  <Button
                    icon="printer"
                    class="receipt-action receipt-show"
                    @click="printReceipts"
                    v-if="$can('PrintPayroll', 'STM') && !externalStamp"
                  >
                    Imprimir
                  </Button>
                  <Dropdown
                    class="receipt-show dropdown-text"
                    v-if="$can('MailNotificationReport', 'STM')"
                  >
                    <Button slot="trigger" icon="file-document" class="receipt-action">
                      Reportes
                      <i class="mdi mdi-chevron-down"></i>
                    </Button>
                    <DropdownItem
                      @click="onGenerateReport"
                      v-if="$can('MailNotificationReport', 'STM')"
                    >
                      Estatus Notificaciones
                    </DropdownItem>
                  </Dropdown>
                  <Dropdown
                    class="receipt-show dropdown-text"
                    v-if="$can('DeletePayroll', 'STM') || $can('DeletePerdeducConcept', 'STM')"
                  >
                    <Button slot="trigger" icon="delete" class="receipt-action">
                      Eliminar
                      <i class="mdi mdi-chevron-down"></i>
                    </Button>
                    <DropdownItem @click="onDelete" v-if="$can('DeletePayroll', 'STM')">
                      Eliminar Recibos
                    </DropdownItem>
                    <DropdownItem
                      @click="onDeleteConcepts"
                      v-if="$can('DeletePerdeducConcept', 'STM')"
                    >
                      Eliminar Conceptos
                    </DropdownItem>
                  </Dropdown>
                  <Dropdown
                    position="bottom-right"
                    class="dropdown-other-actions"
                    v-if="
                      $can('DownloadPayroll', 'STM') ||
                      $can('PrintPayroll', 'STM') ||
                      $can('DownloadPayroll', 'STM')
                    "
                  >
                    <Button slot="trigger" class="receipt-action" icon="dots-vertical">
                      Otras acciones
                      <i class="mdi mdi-chevron-down"></i>
                    </Button>
                    <DropdownItem
                      :containChild="true"
                      text="Eliminar"
                      v-if="$can('DeletePayroll', 'STM') || $can('DeletePerdeducConcept', 'STM')"
                    >
                      <DropdownItem @click="onDelete" v-if="$can('DeletePayroll', 'STM')">
                        Eliminar Recibos
                      </DropdownItem>
                      <DropdownItem
                        @click="onDeleteConcepts"
                        v-if="$can('DeletePerdeducConcept', 'STM')"
                      >
                        Eliminar Conceptos
                      </DropdownItem>
                    </DropdownItem>
                    <DropdownItem
                      :containChild="true"
                      text="Reportes"
                      v-if="$can('MailNotificationReport', 'STM')"
                    >
                      <DropdownItem
                        @click="onGenerateReport"
                        v-if="$can('MailNotificationReport', 'STM')"
                      >
                        Estatus Notificaciones
                      </DropdownItem>
                    </DropdownItem>
                    <DropdownItem
                      :containChild="true"
                      text="Descargar"
                      v-if="$can('DownloadPayroll', 'STM')"
                    >
                      <DropdownItem @click="() => confirmDownload('FilePDF')">
                        Descargar PDF
                      </DropdownItem>
                      <DropdownItem @click="() => confirmDownload('FileXML')">
                        Descargar XML
                      </DropdownItem>
                      <DropdownItem @click="() => confirmDownload('FilePDF,FileXML')">
                        Descargar ambos
                      </DropdownItem>
                    </DropdownItem>
                    <DropdownItem
                      @click="confirmSendEmail"
                      :disabled="loadingSentReceipts"
                      v-if="$can('SendMail', 'STM')"
                    >
                      Enviar CFDI por correo
                    </DropdownItem>
                    <DropdownItem @click="printReceipts" v-if="$can('PrintPayroll', 'STM')">
                      Imprimir CFDI
                    </DropdownItem>
                  </Dropdown>
                </div>
              </transition>
              <div class="receipts-resume" v-if="summary && !showEmpty">
                <Button
                  :icon="summaryIcon"
                  @mouseover="hoverSummary = true"
                  @mouseleave="hoverSummary = false"
                  :title="keepResumeOpen ? 'desfijar' : 'fijar'"
                  color="secondary-outlined"
                  class="receipt-resume__button"
                  @click="setKeepResumeOpen"
                />
                <PayrollReceiptsResume :summary="summary" :keepOpen.sync="keepResumeOpen" />
              </div>
            </div>
          </Column>
        </Layout>
      </div>

      <div>
        <transition name="fade">
          <PayrollReceiptsFilter v-show="!hideFilter" @executeQuery="executeQuery" ref="filter" />
        </transition>

        <Layout v-if="showError">
          <Column size="4" />
          <Column size="4">
            <ActionCard
              image="/assets/filter-start.svg"
              text="Ocurrió un problema, intentalo nuevamente."
            />
          </Column>
        </Layout>
        <Layout v-if="showBlank">
          <Column size="4" />
          <Column size="4">
            <ActionCard
              image="/assets/filter-start.svg"
              text="Indica los parámetros para la búsqueda de los recibos de nómina de tu equipo."
            />
          </Column>
        </Layout>
        <Layout v-if="showEmpty">
          <Column size="4" />
          <Column size="4">
            <ActionCard
              image="/assets/blank.svg"
              text="No hay recibos de nómina con los criterios de búsqueda, intentelo nuevamente."
            />
          </Column>
        </Layout>
      </div>

      <Column size="12" class="receipts-errors" v-if="errors">
        <span class="mdi mdi-information">
          <span>
            Los registros marcados con un punto amarillo presentan errores en el proceso de timbrado
            o cancelación.
            <a href="#!" @click="downloadReceiptsErrors">Descarga el reporte de errores aquí.</a>
          </span>
        </span>
      </Column>

      <Grid
        class="receipts-grid"
        :scrollable="true"
        :height="500"
        :data="receipts"
        :actions="actions"
        :showAdd="false"
        :paginatedByServer="true"
        :pageSize="pagination.PageSize"
        :totalItems="pagination.TotalRecords"
        :selectable="
          $can('StampPayroll', 'STM') ||
          $can('CancelPayroll', 'STM') ||
          $can('DownloadPayroll', 'STM') ||
          $can('SendMail', 'STM') ||
          $can('PrintPayroll', 'STM') ||
          $can('DeletePayroll', 'STM') ||
          $can('DeletePerdeducConcept', 'STM')
        "
        customKey="timPayrollEmployeeID"
        @changePage="changePage"
        @checkItem="checkItem"
        @uncheckItem="uncheckItem"
        @checkAll="checkAll"
        @checkCurrentPage="checkCurrentPage"
        @uncheckAll="uncheckAll"
        @uncheckCurrentPage="uncheckCurrentPage"
        v-if="receipts.length !== 0"
      >
        <template slot-scope="{ row }" tag="tr">
          <GridColumn title="Est">
            <PayrollReceiptStatusIcon :receipt="row" />
          </GridColumn>
          <GridColumn title="Periodo">
            {{ row.idPaymentPeriod }}
          </GridColumn>
          <GridColumn title="Colaborador">
            <span @dblclick="() => showEmployeeReceipts(row)" style="cursor: pointer">
              {{ `${row.idEmployee} - ${row.employeeFullName}` }}
            </span>
          </GridColumn>
          <GridColumn title="Percepciones">
            {{ row.perc }}
          </GridColumn>
          <GridColumn title="Deducciones">
            {{ row.deduc }}
          </GridColumn>
          <GridColumn title="Otros pagos">
            {{ row.otherPay }}
          </GridColumn>
          <GridColumn title="Neto">
            {{ row.net }}
          </GridColumn>
          <GridColumn title="F. de pago">
            {{ row.paymentDate }}
          </GridColumn>
          <GridColumn title="Adicionales" v-if="!externalStamp">
            <PayrollReceiptsAddons :receipt="row" />
          </GridColumn>
        </template>
      </Grid>
    </Frame>

    <router-view></router-view>

    <Modal :active.sync="showPaymentChooserModal" :width="520">
      <PayrollReceiptsPaymentPeriodChooser
        :data="paymentPeriodChoices"
        :checkStatus="confirmCheckStatus"
      />
    </Modal>
    <Modal :active.sync="showDateModal" class="modal-receipts-stamp">
      <PayrollReceiptsStampWithDate :actionReceipts="actionReceipts" :receiptID="receiptInView" />
    </Modal>
    <Modal :active.sync="showPrintModal" class="modal-receipts-print">
      <PayrollReceiptsPrint :printReceipts="doprintReceipts" />
    </Modal>
    <Modal :active.sync="showSummaryProcessModal" :width="500">
      <StampConfirmation :summaryProcess="summaryProcess" @startStamp="verifyPrecancel" />
    </Modal>
    <Modal :active.sync="showPrecancelSummaryModal" :width="750">
      <StampPrecancelConfirmation
        :precancelSummary="precancelSummary"
        @confirm="
          () => {
            this.showPrecancelSummaryModal = false;
            this.actionReceiptsC('T', summaryProcess.PaymentDate, summaryProcess.receiptID, false);
          }
        "
      />
    </Modal>
    <Modal :active.sync="showCancelChooserModal" :width="500">
      <CancelChooser
        @cancel="
          () => {
            this.showCancelChooserModal = false;
            this.actionReceiptsC('C', cancelProps.PaymentDate, cancelProps.receiptID);
          }
        "
        @precancel="
          () => {
            this.showCancelChooserModal = false;
            this.$nextTick(() => {
              this.showCancelConfirmModal = true;
            });
          }
        "
      />
    </Modal>
    <Modal :active.sync="showCancelConfirmModal" :width="500">
      <CancelConfirmation
        :receiptsCounter="countSelected"
        @confirm="precancelReceipts(cancelProps.receiptID)"
      />
    </Modal>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import CancelConfirmation from '@/views/modules/payrollReceipts/CancelConfirmation.vue';
import CancelChooser from '@/views/modules/payrollReceipts/CancelChooser.vue';
import StampPrecancelConfirmation from '@/views/modules/payrollReceipts/StampPrecancelConfirmation.vue';
import PayrollReceiptStatusIcon from './PayrollReceiptStatusIcon.vue';
import PayrollReceiptsAddons from './PayrollReceiptsAddons.vue';
import PayrollReceiptsFilter from './PayrollReceiptsFilter.vue';
import PayrollReceiptsResume from './PayrollReceiptsResume.vue';
import PayrollReceiptsPaymentPeriodChooser from './PayrollReceiptsPaymentPeriodChooser.vue';
import PayrollReceiptsStampWithDate from './PayrollReceiptsStampWithDate.vue';
import PayrollReceiptsPrint from './PayrollReceiptsPrint.vue';
import StampConfirmation from './StampConfirmation.vue';

export default {
  name: 'PayrollReceipts',
  components: {
    StampPrecancelConfirmation,
    CancelChooser,
    CancelConfirmation,
    StampConfirmation,
    PayrollReceiptsPaymentPeriodChooser,
    PayrollReceiptStatusIcon,
    PayrollReceiptsAddons,
    PayrollReceiptsFilter,
    PayrollReceiptsResume,
    PayrollReceiptsStampWithDate,
    PayrollReceiptsPrint,
  },
  data() {
    return {
      externalStamp: false,
      keepResumeOpen: false,
      hoverSummary: false,
      urlApiStamp: process.env.VUE_APP_ROOT_API,
      hideFilter: false,
      showBlank: true,
      showDateModal: false,
      showPrintModal: false,
      showPaymentChooserModal: false,
      showSummaryProcessModal: false,
      showPrecancelSummaryModal: false,
      showEmpty: false,
      showError: false,
      loading: false,
      loadingSentReceipts: false,
      lastQuery: null,
      actions: [
        {
          text: 'Ver colaborador',
          callback: this.showEmployeeReceipts,
        },
      ],
      query: {
        Companies: [],
        CumulativeMonths: [11],
        CumulativeYear: 2019,
        Employees: [],
        EmployerRegistrations: [],
        Locations: [],
        PaymentPeriod: [],
        PaymentPeriodicity: [],
        PayrollSchema: [],
        StatusCFDI: [2, 4],
      },
      receipts: [],
      pagination: {
        PageNumber: 1,
        PageSize: 50,
        TotalRecords: null,
        TotalPages: null,
      },
      receiptsSelected: [],
      receiptInView: null,
      cfdiStatuses: [
        // {
        //   Code: 'STAMPED',
        //   Description: 'Timbrado',
        // },
        {
          Code: 'FAILED',
          Description: 'Fallido',
        },
        {
          Code: 'CANCELLED',
          Description: 'Cancelado',
        },
      ],
      catalogPeriods: [],
      paymentPeriodChoices: [],
      allSelected: false,
      summary: null,
      summaryProcess: null,
      errors: null,
      isCFDIv4: false,
      showCancelChooserModal: false,
      showCancelConfirmModal: false,
      cancelProps: {},
      precancelSummary: {},
    };
  },
  mounted() {
    if (this.$can('ViewPayrollKardex', 'STM'))
      this.actions.push({
        text: 'Ver nómina',
        callback: (item) =>
          this.$router.push({
            name: 'kardex',
            params: { id: item.timPayrollEmployeeID },
          }),
      });

    if (this.$can('DeletePayroll', 'STM'))
      this.actions.push({
        text: 'Eliminar nómina',
        callback: this.deletePayroll,
      });

    // Verify EXTERNALSTAMP
    this.loading = true;
    this.getAll('Configuration/Org/ConceptConfiguration', 'EXTERNALSTAMP', 'stamp')
      .then((data) => {
        this.externalStamp = data.valueBit;
        this.loading = false;
      })
      .catch(() => {
        this.externalStamp = false;
        this.loading = false;
      });

    // Verify CFDI v 4.0
    this.isCFDIv4 = true;
  },
  methods: {
    onClickFilter() {
      this.keepResumeOpen = false;
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        this.hideFilter = false;
      } else {
        this.hideFilter = !this.hideFilter;
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    onClickRefresh() {
      this.keepResumeOpen = false;
      this.$refs.filter.executeQuery();
    },
    confirmCancelations() {
      this.$post('stamp/Org/CheckCancelInProcess', {}, 'stamp').then(
        () => {
          this.setNotification(
            'La solicitud de confirmación de cancelación ha sido enviada exitosamente.',
            'success',
            'Confirmación enviada exitosamente'
          );
        },
        (error) => {
          this.setNotification(error.toJSON().message, 'warning');
        }
      );
    },
    checkStatus() {
      let currentPeriod = null;
      if (this.query.PaymentPeriod.length === 0) {
        if (this.catalogPeriods.length === 1)
          currentPeriod = this.catalogPeriods.find((x) => x.id === this.catalogPeriods[0]);
        else {
          this.paymentPeriodChoices = this.catalogPeriods;
          this.showPaymentChooserModal = true;
        }
      } else if (this.query.PaymentPeriod.length === 1)
        currentPeriod = this.catalogPeriods.find((x) => x.id === this.query.PaymentPeriod[0]);
      else {
        this.paymentPeriodChoices = this.catalogPeriods.filter((x) =>
          this.query.PaymentPeriod.includes(x.id)
        );
        this.showPaymentChooserModal = true;
      }

      if (currentPeriod) this.confirmCheckStatus(currentPeriod);
    },
    // eslint-disable-next-line no-unused-vars
    confirmCheckStatus(paymentPeriod) {
      this.showConfirm(
        // `¿Deseas comprobar timbrados del periodo ${paymentPeriod.label || ''}?`,
        `¿Deseas comprobar los timbrados de los periodos dentro de los filtros seleccionados?`,
        `Esto verificará el estatus de los recibos dentro de dichos periodos.`,
        'information',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: { color: 'terciary-outlined' },
          },
          {
            text: 'Confirmar',
            // callback: () => { this.doChekStatus(paymentPeriod) },
            callback: () => {
              this.doChekStatus();
            },
            props: { color: 'secondary' },
          },
        ]
      );
    },
    // eslint-disable-next-line no-unused-vars
    doChekStatus(paymentPeriod) {
      const query = { filters: this.query };
      // query.PaymentPeriod = [paymentPeriod.id];

      this.loading = true;
      this.$post('stamp/Org/AutomaticCheckStatus', query, 'stamp')
        .then(() => {
          this.loading = false;
          this.setNotification('Verificación de timbrados exitoso.', 'success');
        })
        .catch(({ response }) => {
          this.loading = false;

          const msg = response
            ? response.data.operationMessage || response.data
            : 'Ocurrió algún error.';
          this.setNotification(msg, 'warning');
        });
    },
    downloadReceiptsErrors() {
      this.$stream('stamp/org/ReceiptsErrors', this.query, 'stamp', 'POST', {
        responseType: 'arraybuffer',
      })
        .then((response) => {
          const { data, headers } = response;
          const disposition = headers['content-disposition'];
          let filename = '';
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
          const blob = new Blob([data], { type: headers['content-type'] });
          const FileDownload = require('js-file-download');
          FileDownload(blob, filename);
        })
        .catch(() => {
          this.setNotification('Ocurrió un error al descargar el archivo', 'warning');
        });
    },
    printReceipts() {
      this.showPrintModal = true;
    },
    confirmSendEmail() {
      const many = this.countSelected > 1;

      this.showConfirm(
        `¿Deseas enviar notificación por correo ${!many ? 'del' : `de los ${this.countSelected}`}` +
          ` recibo${many ? 's' : ''} seleccionado${many ? 's' : ''}?`,
        `Esto enviará un correo al email ${many ? 'de los colaboradores' : 'del colaborador'}
        ${many ? 'de los' : 'del'} recibo${many ? 's' : ''} de nómina` +
          ` seleccionado${many ? 's' : ''}.`,
        'information',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: { color: 'terciary-outlined' },
          },
          {
            text: 'Enviar',
            callback: () => {
              this.sentReceipts();
            },
            props: { color: 'secondary' },
          },
        ]
      );
    },
    sentReceipts() {
      this.loadingSentReceipts = true;
      const nowTime = DateTime.local();

      const excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
      const selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];

      const query = {
        ...this.query,
        SelectedPayrollEmployee: selectedPayrollEmployees,
        excludedPayrollEmployees: excludedPayrollEmployees,
        ForceSending: false,
        sendDateTime: nowTime.toString(),
        IANA_TimeZone: nowTime.zoneName,
      };

      this.$post('stamp/org/email/sendcfdi', query, 'stamp')
        .then(() => {
          this.loadingSentReceipts = false;
          this.setNotification('Correos en proceso de envío.', 'success');
        })
        .catch(({ response }) => {
          this.loadingSentReceipts = false;
          this.setNotification(response.data.operationMessage || response.data, 'warning');
        });
    },
    showEmployeeReceipts(item) {
      this.$router.push({
        name: 'employeeInReceipts',
        params: {
          id: item.idEmployee,
        },
        query: {
          callbackURI: 'payrollReceipts',
        },
      });
    },
    deletePayroll(item) {
      this.showConfirm(
        '¿Deseas eliminar la nómina seleccionada?',
        'Esto eliminará la información de la nómina de los colaboradores seleccionados en el periodo correspondiente.',
        'danger',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: {
              color: 'terciary-outlined',
            },
          },
          {
            text: 'Eliminar',
            callback: () => this.deleteConfirmed(item.timPayrollEmployeeID),
            props: {
              color: 'quinary',
            },
          },
        ]
      );
    },
    doprintReceipts(orderOptions) {
      const excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
      const selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];

      const query = {
        filters: this.query,
        excludedPayrollEmployees,
        selectedPayrollEmployees,
        sortingBy: orderOptions,
      };
      this.$stream('stamp/org/receipts/print', query, 'stamp', 'POST', {
        responseType: 'arraybuffer',
      }).then((response) => {
        const { data, headers } = response;
        const disposition = headers['content-disposition'];
        let filename = '';
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        const blob = new Blob([data], { type: headers['content-type'] });
        const FileDownload = require('js-file-download');
        FileDownload(blob, filename);
      });
    },
    stampReceipts(events, receiptID) {
      if (this.summary && this.summary.allowEditPaymentDate) {
        this.receiptInView = receiptID;
        this.showDateModal = true;
      } else {
        this.actionReceipts('T', null, receiptID);
      }
    },
    changeStatusReceipts(Status) {
      const excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
      const selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];

      const query = {
        filters: this.query,
        excludedPayrollEmployees,
        selectedPayrollEmployees,
        StatusCode: Status.Code,
      };

      const many = this.countSelected > 1;
      this.showConfirm(
        `¿Deseas cambiar el estatus ${!many ? 'del' : `de los ${this.countSelected}`} recibo${
          many ? 's' : ''
        } seleccionado${many ? 's' : ''} a ${Status.Description}?`,
        `Esto enviará la solicitud
        ${many ? 'de los' : 'del'} recibo${many ? 's' : ''} de nómina` +
          ` seleccionado${many ? 's' : ''}.`,
        'information',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: { color: 'terciary-outlined' },
          },
          {
            text: 'Cambiar el estatus',
            callback: () => {
              this.loading = true;
              this.$post('stamp/org/ChangeStatus', query, 'stamp')
                .then(({ data }) => {
                  this.loading = false;
                  this.setNotification(data.operationMessage);
                  this.receiptsSelected = [];
                  this.allSelected = false;
                  this.getReceipts(this.query);
                })
                .catch(({ response }) => {
                  this.loading = false;
                  this.setNotification(
                    response.data.operationMessage || response.data.StampProcessMessageResult,
                    'warning'
                  );
                });
            },
            props: { color: 'secondary' },
          },
        ]
      );
    },
    onGenerateReport() {
      const OrgCode = localStorage.getItem('OCode');
      const excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
      const selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];

      const query = {
        org: OrgCode,
        doc: 'MAILNOTIREPORT',
        lang: 'es',
        format: 'excel',
        UserName: this.user.profile.UserName,
        OrganizationCode: OrgCode,
        CumulativeYear: this.query.CumulativeYear,
        CumulativeMonthsR: this.query.CumulativeMonths.join(),
        PaymentPeriodicityR: this.query.PaymentPeriodicity.join(),
        PaymentPeriodR: this.query.PaymentPeriod.join(),
        CompaniesR: this.query.Companies.join(),
        EmployerRegistrationsR: this.query.EmployerRegistrations.join(),
        PayrollSchemasR: this.query.PayrollSchema.join(),
        PayrollCFDIStatusesR: this.query.StatusCFDI.join(),
        MailNotificationStatusesR: this.query.StatusMail.join(),
        EmployeesR: this.query.Employees.join(),
        tblPayrollEmpToIncludeR: selectedPayrollEmployees.join(),
        tblPayrollEmpToExcludeR: excludedPayrollEmployees.join(),
        LocationsR: this.query.Locations.join(),
        DepartmentsR: this.query.departments.join(),
        JobPositionsR: this.query.jobpositions.join(),
      };

      const queryString = new URLSearchParams(query).toString();

      const urlBase = `${process.env.VUE_APP_ROOT_API_ROOT}document/?${queryString}`;
      const link = document.createElement('a');
      link.setAttribute('href', urlBase);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onDelete(itemID) {
      const many = this.countSelected > 1;
      const currentTitle = itemID
        ? 'el recibo actual?'
        : `${!many ? 'el' : `los ${this.countSelected}`} recibo${many ? 's' : ''}
         seleccionado${many ? 's' : ''}?`;
      const currentContent = itemID ? '' : ` seleccionado${many ? 's' : ''}`;

      this.showConfirm(
        `¿Deseas eliminar ${currentTitle}`,
        `Esto eliminará ${many ? 'los' : 'el'} recibo${many ? 's' : ''}
         de nómina ${many ? 'de los' : 'del'} colaborador${many ? 'es' : ''}${currentContent},
          en el periodo correspondiente.`,
        'danger',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: {
              color: 'terciary-outlined',
            },
          },
          {
            text: `Eliminar recibo${many ? 's' : ''}`,
            callback: () => this.deleteConfirmed(itemID),
            props: {
              color: 'quinary-outlined',
            },
          },
        ]
      );
    },
    onDeleteConcepts(itemID) {
      const many = this.countSelected > 1;
      const current = itemID
        ? 'del recibo actual?'
        : `${!many ? 'del' : `de los ${this.countSelected}`} recibo${many ? 's' : ''}
         seleccionado${many ? 's' : ''}?`;

      this.showConfirm(
        `¿Deseas eliminar los conceptos de nómina ${current}`,
        `Esto eliminará los conceptos ${many ? 'de los' : 'del'} recibo${many ? 's' : ''}
         , dejando ${many ? 'cada' : 'la'} nómina sin detalle de conceptos.`,
        'danger',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: {
              color: 'terciary-outlined',
            },
          },
          {
            text: `Eliminar conceptos`,
            callback: () => this.deleteConfirmed(itemID, 'Payroll'),
            props: {
              color: 'quinary-outlined',
            },
          },
        ]
      );
    },
    deleteConfirmed(itemID, anotherEndpoint) {
      const self = this;
      let endpoint = 'stamp/org/receipt';
      let excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
      let selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];
      if (itemID) {
        excludedPayrollEmployees = [];
        selectedPayrollEmployees = [itemID];
      }
      if (anotherEndpoint) {
        endpoint = anotherEndpoint;
      }
      const query = {
        filters: this.query,
        excludedPayrollEmployees,
        selectedPayrollEmployees,
      };

      this.$masiveDelete(endpoint, query, 'stamp')
        .then((res) => {
          if (res.data.operationResult >= 0) {
            self.executeQuery(this.lastQuery);
          } else {
            self.setNotification(res.data.operationMessage, 'warning');
          }
        })
        .catch(({ response }) => {
          this.setNotification(response.data.operationMessage, 'warning');
        });
    },
    downLoadFile(DocumentType, PayrollEmployee) {
      const query = {
        FileType: DocumentType,
        PayrollEmployee: PayrollEmployee,
      };

      const fileTypes = {
        FileXML: 'data:application/xml;base64,',
        FilePDF: 'data:application/pdf;base64,',
      };

      this.$stream('storage/DownloadFile', query, 'stamp')
        .then(({ data }) => {
          const link = document.createElement('a');
          link.href = `${fileTypes[DocumentType]}${data.File64}`;
          link.setAttribute('download', data.FileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(({ response }) => {
          this.setNotification(response.data, 'warning');
        });
    },
    actionReceipts(ActionType, PaymentDate = null, receiptID) {
      if (ActionType === 'T') {
        const excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
        const selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];

        const query = {
          ActionType: '',
          PaymentDate: null,
          filters: this.query,
          excludedPayrollEmployees,
          selectedPayrollEmployees,
        };
        this.loading = true;
        this.$post('stamp/org/summaryProcess', query, 'stamp')
          .then(({ data }) => {
            this.loading = false;
            this.summaryProcess = { ...data, PaymentDate, receiptID };
            this.showSummaryProcessModal = true;
          })
          .catch(() => {
            this.loading = false;
          });
        return;
      }

      if (this.isCFDIv4) {
        this.showCancelChooserModal = true;
        this.cancelProps = { PaymentDate, receiptID };
        return;
      }
      this.actionReceiptsC(ActionType, PaymentDate, receiptID);
    },
    async verifyPrecancel() {
      const { PaymentDate, receiptID } = this.summaryProcess;
      this.showSummaryProcessModal = false;

      if (this.isCFDIv4) {
        let excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
        let selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];

        if (receiptID) {
          excludedPayrollEmployees = [];
          selectedPayrollEmployees = [receiptID];
        }

        const query = {
          filters: this.query,
          excludedPayrollEmployees,
          selectedPayrollEmployees,
        };
        this.loading = true;
        const data = await this.$post('stamp/org/GetPrecancelCounter', query, 'stamp').then(
          // eslint-disable-next-line no-shadow
          ({ data }) => data
        );
        this.loading = false;

        if (data.precancelCount > 0) {
          this.showPrecancelSummaryModal = true;
          this.precancelSummary = data;
          this.precancelSummary.query = query;
          return;
        }
      }
      this.actionReceiptsC('T', PaymentDate, receiptID);
    },
    actionReceiptsC(ActionType, PaymentDate = null, receiptID, askConfirmation = true) {
      this.receiptInView = null;

      let excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
      let selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];

      if (receiptID) {
        excludedPayrollEmployees = [];
        selectedPayrollEmployees = [receiptID];
      }

      const query = {
        ActionType,
        PaymentDate,
        filters: this.query,
        excludedPayrollEmployees,
        selectedPayrollEmployees,
      };
      if (!askConfirmation) {
        this.confirmStampReceipts(query);
        return;
      }

      const many = this.countSelected > 1;
      const cancel = ActionType === 'C';

      const title = this.externalStamp
        ? `¿Deseas generar o enviar ${!many ? 'el' : `los ${this.countSelected}`} recibo${
            many ? 's' : ''
          } seleccionado${many ? 's' : ''} para timbrar?`
        : `¿Deseas ${cancel ? 'cancelar' : 'timbrar'} ${
            !many ? 'el' : `los ${this.countSelected}`
          } recibo${many ? 's' : ''}${receiptID ? '?' : ` seleccionado${many ? 's' : ''}?`}`;

      const text = this.externalStamp
        ? 'Esto generará o enviará los recibos de nómina seleccionados para timbrar.'
        : `Esto enviará al SAT, la solicitud de ${cancel ? 'cancelación' : 'timbrado'}
        ${many ? 'de los' : 'del'} recibo${many ? 's' : ''} de nómina${
            receiptID ? '.' : ` seleccionado${many ? 's' : ''}.`
          }`;

      this.showConfirm(title, text, cancel ? 'danger' : 'information', [
        {
          text: 'Salir',
          callback: () => {},
          props: { color: 'terciary-outlined' },
        },
        {
          text: `${cancel ? 'Cancelar' : 'Timbrar'} recibo${many ? 's' : ''}`,
          callback: () => {
            this.loading = true;
            this.confirmStampReceipts(query);
          },
          props: { color: cancel ? 'quinary-outlined' : 'secondary' },
        },
      ]);
    },
    confirmStampReceipts(query) {
      this.$post('stamp/org/process', query, 'stamp')
        .then(({ data }) => {
          this.loading = false;
          if (data.BatchCFDIProcessID > 0) {
            this.setNotification(data.StampProcessMessageResult);
            this.receiptsSelected = [];
            this.allSelected = false;
            this.getReceipts(this.query);
          } else {
            this.setNotification(data.StampProcessMessageResult, 'warning');
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          this.setNotification(response.data.StampProcessMessageResult, 'warning');
        });
    },
    precancelReceipts(receiptID) {
      let excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
      let selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];

      if (receiptID) {
        excludedPayrollEmployees = [];
        selectedPayrollEmployees = [receiptID];
      }

      const query = {
        filters: this.query,
        excludedPayrollEmployees,
        selectedPayrollEmployees,
      };

      this.loading = true;
      this.showCancelConfirmModal = false;
      this.$post('stamp/org/ChangeStatusPrecancel', query, 'stamp')
        .then(({ data }) => {
          this.loading = false;
          if (data.operationResult > 0) {
            this.setNotification(data.operationMessage);
            this.receiptsSelected = [];
            this.allSelected = false;
            this.getReceipts(this.query);
          } else {
            this.setNotification(data.operationMessage, 'warning');
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          this.setNotification(response.data.operationMessage, 'warning');
        });
    },
    confirmDownload(fileType) {
      const types = fileType.replaceAll('File', '').replace(',', ' y ');
      const many = this.countSelected > 1;

      this.showConfirm(
        `¿Deseas descargar ${!many ? 'el' : 'los'} ${types} ${
          !many ? 'del' : `de los ${this.countSelected}`
        } recibo${many ? 's' : ''} seleccionado${many ? 's' : ''}?`,
        `Esto descargará en un zip ${many || types.includes('y') ? 'los archivos' : 'el archivo'}
        ${many ? 'de los' : 'del'} recibo${many ? 's' : ''} de nómina` +
          ` seleccionado${many ? 's' : ''}.`,
        'information',
        [
          {
            text: 'Cancelar',
            callback: () => {},
            props: { color: 'terciary-outlined' },
          },
          {
            text: 'Descargar',
            callback: () => {
              this.downloadReceipts(fileType);
            },
            props: { color: 'secondary' },
          },
        ]
      );
    },
    downloadReceipts(fileType) {
      const SelectedFile = fileType.split(',');
      const excludedPayrollEmployees = this.allSelected ? this.receiptsSelected : [];
      const selectedPayrollEmployees = !this.allSelected ? this.receiptsSelected : [];
      const query = {
        filters: {
          ...this.query,
          SelectedFile,
        },
        excludedPayrollEmployees,
        selectedPayrollEmployees,
      };

      this.loading = true;
      this.$stream('storage/DownloadZip/', query, 'stamp')
        .then(({ data }) => {
          if (data === 'Link email') {
            this.setNotification(
              'Se generará el archivo ZIP con los comprobantes solicitados, en cuanto esté disponible te notificaremos por correo.',
              'Descarga complementos'
            );
          } else {
            const link = document.createElement('a');
            link.href = `data:application/zip;base64,${data.File64}`;
            link.setAttribute('download', data.FileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.loading = false;
        })
        .catch(({ response }) => {
          this.setNotification(response.data, 'warning');
          this.loading = false;
        });
    },
    checkAll() {
      this.allSelected = true;
      this.receiptsSelected = [];
      this.receipts.forEach((x) => {
        x.selected = true;
      });
    },
    checkCurrentPage() {
      this.receipts.forEach((x) => {
        x.selected = true;
        if (this.allSelected) {
          if (this.receiptsSelected.find((y) => y === x.timPayrollEmployeeID)) {
            this.receiptsSelected = this.receiptsSelected.filter(
              (y) => y !== x.timPayrollEmployeeID
            );
          }
        } else if (!this.receiptsSelected.find((y) => y === x.timPayrollEmployeeID)) {
          this.receiptsSelected.push(x.timPayrollEmployeeID);
        }
      });
    },
    uncheckAll() {
      this.allSelected = false;
      this.receiptsSelected = [];
      this.receipts.forEach((x) => {
        x.selected = false;
      });
    },
    uncheckCurrentPage() {
      this.receipts.forEach((x) => {
        x.selected = false;
        if (!this.allSelected) {
          if (this.receiptsSelected.find((y) => y === x.timPayrollEmployeeID)) {
            this.receiptsSelected = this.receiptsSelected.filter(
              (y) => y !== x.timPayrollEmployeeID
            );
          }
        } else if (!this.receiptsSelected.find((y) => y === x.timPayrollEmployeeID)) {
          this.receiptsSelected.push(x.timPayrollEmployeeID);
        }
      });
    },
    checkItem(item) {
      if (this.allSelected) {
        this.receiptsSelected = this.receiptsSelected.filter(
          (x) => x !== item.timPayrollEmployeeID
        );
      } else {
        this.receiptsSelected.push(item.timPayrollEmployeeID);
      }
    },
    uncheckItem(item) {
      if (this.allSelected) {
        this.receiptsSelected.push(item.timPayrollEmployeeID);
      } else {
        this.receiptsSelected = this.receiptsSelected.filter(
          (x) => x !== item.timPayrollEmployeeID
        );
      }
    },
    executeQuery(query) {
      this.keepResumeOpen = false;
      this.lastQuery = query;
      this.allSelected = false;
      this.receipts = [];
      this.receiptsSelected = [];
      this.hideFilter = true;
      this.pagination.PageNumber = 1;
      this.catalogPeriods = query.CatalogPeriods;
      delete query.CatalogPeriods;

      this.getReceipts(query);
    },
    getReceipts(query) {
      this.loading = true;
      this.query = query;
      this.showError = false;
      const endpoint = `stamp/org/receipts?IncludeCount=true&PageNumber=${this.pagination.PageNumber}&PageSize=50`;
      this.$post(endpoint, query, 'stamp')
        .then(({ data, headers }) => {
          const pagination = headers['x-pagination'];
          this.showBlank = false;
          this.pagination = JSON.parse(pagination);
          this.summary = data.amountSummary;
          this.showEmpty = data.receipts.length === 0;

          this.errors = data.totalErrors;

          this.receipts = data.receipts.map((x) => {
            let isSelected = false;
            if (this.allSelected) {
              isSelected = !this.receiptsSelected.find((y) => y === x.timPayrollEmployeeID);
            } else {
              isSelected = !!this.receiptsSelected.find((y) => y === x.timPayrollEmployeeID);
            }

            return {
              selected: isSelected,
              ...x,
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.showError = true;
        });
    },
    changePage(page) {
      this.pagination.PageNumber = page;
      this.getReceipts(this.query);
    },
    setKeepResumeOpen() {
      this.keepResumeOpen = !this.keepResumeOpen;
    },
  },
  computed: {
    ...mapState('User', ['user']),
    countSelected() {
      if (this.allSelected) {
        return this.pagination.TotalRecords - this.receiptsSelected.length;
      }
      return this.receiptsSelected.length;
    },
    summaryIcon() {
      if (this.keepResumeOpen) return 'pin-off';
      if (this.hoverSummary) return 'pin';
      return 'file-document-box-search-outline';
    },
  },
};
</script>

<style lang="scss">
.custom-frame {
  .pw-frame__header {
    margin-bottom: 0;
  }
}
.sticky-tool {
  position: sticky;
  top: 55px;
  background-color: #fff;
  z-index: 100;
  padding-top: 8px;
  padding-bottom: 8px;
}

.receipts-errors {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 7px;
  background-color: #fff4da;

  span {
    //icon
    font-size: 120%;
    color: #ffc848;
    position: relative;
    display: inline;
    vertical-align: baseline;
    margin: 0 0.125em;
    padding: 0;
    span {
      color: #707070;
      font-size: 14px !important;
      vertical-align: middle;
      a {
        text-decoration: none;
        color: #5185fe;
        font-weight: 600;
        &:hover {
          color: #5185fe;
          text-decoration: underline;
        }
      }
    }
  }
}

.receipts-grid {
  position: relative;
  th {
    position: sticky;
    top: 105px;
    z-index: 99;
  }
  .table-actions {
    margin: 0 !important;
  }

  .pw-dropdown__menu {
    top: 133% !important;
  }
}

.receipts-actions {
  position: relative;
}

.receipt-count {
  position: absolute;
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}

.dropdown-other-actions {
  display: none;
  text-align: left;
}

@media all and (max-width: 1300px) {
  .dropdown-other-actions {
    display: inline-block;
  }
  .receipt-show {
    display: none;
  }
}

.modal-receipts-print {
  .pw_modal__modal-content {
    width: 450px;
    overflow: auto;
    .pw-frame__content {
      margin-bottom: 0;
    }
  }
}
.modal-receipts-stamp {
  .pw_modal__modal-content {
    width: 328px;
    height: 494px;
    overflow: hidden;
  }
}

.actions__resume {
  display: flex;
  justify-content: flex-end;
}

.receipt-action {
  color: #294381 !important;
  border-color: transparent !important;
  background-color: transparent !important;
  font-size: 14px !important;
  padding: 8px 8px;
}
.receipt-count {
  color: #5186ff;
  font-size: 14px;
  margin-right: 8px;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
.receipts-resume {
  display: inline;
  position: relative;
  margin-left: 12px;
}

.receipt-resume__button {
  &:hover + .box-resume {
    opacity: 1;
  }
}

.dropdown-text {
  text-align: left !important;
}

.pw-button {
  .pw-button-icon {
    i.mdi {
      &.mdi-sync {
        display: inline-block;
        color: #5186ff;
        font-size: 120%;
        transform: rotate(90deg) !important;
      }
    }
  }
}
</style>
