<template>
  <div>
    <Frame
      title="Envío de CFDI por correo"
      :titleAction="`${receipt.employeeFullName}`"
      class="custom-frame"
      :loading="loading"
    >
      <Layout v-if="hasError">
        <Column size="3" />
        <Column size="6">
          <ActionCard image="/assets/blank.svg" text="Ocurrio un error. Intente nuevamente" />
        </Column>
      </Layout>
      <Layout v-if="!hasStatusMailing">
        <Column size="3" />
        <Column size="6">
          <ActionCard image="/assets/blank.svg" text="No existe información de los correos" />
        </Column>
      </Layout>
      <TabStrip :isVertical="true" class="custom-tab-strip" v-if="mailStatus.length !== 0">
        <span slot="title" slot-scope="props">
          {{ props.title }}
          <span class="tooltiptext">
            {{ localIANA }}
          </span>
        </span>
        <TabStripItem
          v-for="status in mailStatus"
          :title="status.sendDate | moment('DD/MM/YYYY hh:mm a')"
          :key="status.sendID"
        >
          <ExpandTab v-for="(recipient, index) in status.recipients" :key="index">
            <span slot="title">
              {{ recipient.emailAddress }}
              <span class="pw-tag custom-tag" :class="`custom-tag--${recipient.mailStatusCode}`">
                {{ recipient.mailStatusDescription }}
              </span>
            </span>
            <Layout
              v-for="(row, index) in recipient.relatedEvents"
              :key="index"
              class="row-stats-mail"
            >
              <Column size="6">
                <span
                  class="status-icon"
                  :class="`status-icon--${row.mailStatusCode.toUpperCase()}`"
                >
                  <i class="mdi mdi-checkbox-blank-circle"></i>
                </span>
                {{ row.mailStatusDescription }}
              </Column>
              <Column size="3">
                {{ row.eventDate | moment('DD/MM/YYYY') }}
              </Column>
              <Column size="3">
                <span class="tooltiptitle">
                  {{ row.eventDate | moment('hh:mm a') }}
                  <span class="tooltiptext">
                    {{ row.iana || notFound }}
                  </span>
                </span>
              </Column>
              <!-- <Column size="2">
                
              </Column> -->
            </Layout>
          </ExpandTab>
          <br />
          <Column size="8" style="margin-left: 20px">
            <span style="color: #707070; font-size: 12px">
              Nota: El estatus "Abierto" podría no visualizarse de acuerdo al cliente de correo
              desde el que se aperture (Ej. Outlook).
            </span>
          </Column>
        </TabStripItem>
      </TabStrip>
      <hr style="margin-top: 16px !important" />
      <Layout style="margin-bottom: 0">
        <Column align="right">
          <Button color="terciary-outlined" @click="() => $parent.close()">Cancelar</Button>
          <Button color="secondary" v-show="false">Reenvíar CFDI</Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'PayrollReceiptsCFDIEmail',
  props: {
    receipt: Object,
  },
  data() {
    return {
      mailStatus: [],
      hasStatusMailing: true,
      loading: false,
      hasError: false,
      columns: [
        {
          field: 'status',
          title: 'Estatus',
        },
        {
          field: 'date',
          title: 'Fecha',
        },
        {
          field: 'hour',
          title: 'Hora',
        },
      ],
      data: [
        {
          status: 'Abierto',
          statusCode: 'open',
          date: '12/11/2019',
          hour: '11:20 am',
        },
        {
          status: 'Enviado',
          statusCode: 'SENT',
          date: '12/11/2019',
          hour: '11:20 am',
        },
        {
          status: 'Marcado como spam',
          statusCode: 'SPAM',
          date: '12/11/2019',
          hour: '11:20 am',
        },
      ],
      localIANA: DateTime.local().zoneName,
      notFound: 'Not found',
    };
  },
  mounted() {
    // Org/Email/GetCFDISentByEmail/{pkPayrollEmployee}
    this.loading = true;
    this.hasError = false;
    this.getAll(
      `stamp/Org/Email/GetCFDISentByEmail/${this.receipt.timPayrollEmployeeID}`,
      '',
      'stamp'
    )
      .then((data) => {
        this.mailStatus = data.sort((a, b) => b.sendID - a.sendID);
        if (data.length === 0) {
          this.hasStatusMailing = false;
        }
        this.loading = false;
      })
      .catch(() => {
        this.hasError = true;
        this.loading = false;
      });
  },
};
</script>

<style lang="scss">
.custom-tag {
  color: white !important;
  &--SENT {
    background-color: #6ae1c8 !important;
  }
  &--OPEN {
    background-color: #5186ff !important;
  }
  &--BOUNCE {
    background-color: #ff9b43 !important;
  }
  &--BLOCKED {
    background-color: #333333 !important;
  }
  &--SPAM {
    background-color: #ffc848 !important;
  }
  &--DELIVERED {
    background-color: #19bf95;
  }
  &--SOFTBOUNCE {
    background-color: #ff9b43;
  }

  &--HARDBOUNCE {
    background-color: #f25555;
  }
}
.row-stats-mail {
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 12px;
}
.status-icon {
  margin-right: 8px;
  &--SENT {
    color: #6ae1c8;
  }
  &--OPEN {
    color: #5186ff;
  }
  &--BOUNCE {
    color: #ff9b43;
  }
  &--BLOCKED {
    color: #333333;
  }
  &--SPAM {
    color: #ffc848;
  }
  &--DELIVERED {
    color: #19bf95;
  }
  &--SOFTBOUNCE {
    color: #ff9b43;
  }
  &--HARDBOUNCE {
    color: #f25555;
  }
}
.custom-tab-strip {
  .tab-strip__items {
    margin: 0;
  }
  &.pw-tab-strip--is-vertical.pw-tab-strip > .pw-tab-strip__header {
    width: 33.333% !important;
    li {
      font-size: 14px;
    }
  }
}
.custom-grid-cfdi-emails {
  thead {
    display: none;
  }
  .table-actions {
    margin-bottom: 0;
  }
  td {
    background-color: #fff !important;
    border-color: transparent !important;
    font-size: 12px !important;
    color: #707070 !important;
  }
}
.custom-frame {
  padding-bottom: 0 !important;
  .pw-frame__content {
    margin-bottom: 0;
    padding-top: 16px;
  }
}

/* Tooltip container */
.tooltiptitle {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Positions of tooltip texts */
.tab-strip__row .tooltiptext {
  bottom: 115%;
  margin-left: -140px;
}
.tooltiptitle .tooltiptext {
  bottom: 150%;
  margin-left: -30px;
}

/* Tooltip arrow */
.tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tab-strip__row:hover,
.tooltiptitle:hover {
  .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
</style>
