<template>
  <div style="text-align: center">
    <i v-if="this.receipt.error" class="mdi error-icon mdi-circle" :title="this.receipt.error" />
    <i
      class="mdi cfdi-icon"
      :class="`mdi-${iconClass}`"
      :title="this.receipt.cfdiStatus || 'Desconocido'"
    >
    </i>
  </div>
</template>

<script>
export default {
  name: 'PayrollReceiptStatusIcon',
  props: {
    receipt: Object,
  },
  computed: {
    iconClass() {
      const status = {
        STAMPED: 'star',
        CANCELLED: 'cancel',
        PRECANCEL: 'cancel pre-cancel',
        FAILED: 'alert-rhombus',
        INPROC: 'sync',
        PENDING: 'play',
        CANCELPROCESS: 'progress-close',
        UNKNOW: 'help-circle',
        SENT: 'file-send',
      };

      return status[this.receipt.cfdiStatusCode || 'UNKNOW'];
    },
  },
};
</script>

<style lang="scss">
.error-icon {
  position: absolute;
  font-size: 8px;
  margin-inline-start: -17px;
  color: #ffc848;
}

.cfdi-icon {
  font-size: 18px;
  margin-inline-start: -5px;
  &.mdi-star {
    color: #19bf95;
  }
  &.mdi-cancel:not(.pre-cancel) {
    color: #f25555;
  }
  &.mdi-cancel.pre-cancel {
    color: #ffc848;
  }
  &.mdi-play {
    color: #6ae1c8;
  }
  &.mdi-sync {
    color: #6ae1c8;
  }
  &.mdi-alert-rhombus {
    color: #ffc848;
  }
  &.mdi-progress-close {
    color: #f25555;
  }
  &.mdi-help-circle {
    color: #a5acbc;
  }
  &.mdi-file-send {
    color: #19bf95;
  }
}
</style>
