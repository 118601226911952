<template>
  <div>
    <Frame title="Elegir periodo">
      <Layout>
        <Column>
          <Field label="Comprobar timbrados de:">
            <MultiSelect :options="data" v-model="periodSelected" />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button color="terciary-outlined" @click="$parent.close()">Cancelar</Button>
          <Button color="secondary" :disabled="!periodSelected" @click="onChoose">Aceptar</Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'PayrollReceiptsPaymentPeriodChooser',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    checkStatus: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      periodSelected: null,
    };
  },
  methods: {
    onChoose() {
      const selected = this.data.find((x) => x.id === this.periodSelected);

      this.checkStatus.apply(null, [selected]);
      this.$parent.close();
    },
  },
};
</script>
