<template>
  <div>
    <Frame title="Imprimir CFDI">
      <Layout>
        <Column>
          <Field label="Ordenar por">
            <MultiSelect
              :options="orderPrintOptions"
              :multiple="true"
              v-model="orderPrintOptionsSelected"
            />
          </Field>
        </Column>
      </Layout>
      <Layout>
        <Column>
          <ActionCard
            class="custom-action-card"
            image="/assets/empty-image.svg"
            text="Indica cómo deseas ordenar los recibos. En el orden en el que los selecciones serán organizados."
          />
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button color="terciary-outlined" @click="$parent.close()">Cancelar</Button>
          <Button
            color="secondary"
            :disabled="orderPrintOptionsSelected.length === 0"
            @click="onPrint"
            >Aceptar</Button
          >
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'PayrollReceiptsPrint',
  props: {
    printReceipts: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      orderPrintOptionsSelected: [],
      orderPrintOptions: [
        {
          id: 'IDEmployee',
          label: 'Código Colaborador',
        },
        {
          id: 'EmployeeLastName',
          label: 'Apellido Paterno Colaborador',
        },
        {
          id: 'EmployeeSecondLastName',
          label: 'Apellido Materno Colaborador',
        },
        {
          id: 'EmployeeName',
          label: 'Nombre Colaborador',
        },
        {
          id: 'CompanyDescription',
          label: 'Razón Social',
        },
        {
          id: 'LocationCode',
          label: 'Código Unidad Negocio',
        },
        {
          id: 'LocationDescription',
          label: 'Unidad Negocio',
        },
        {
          id: 'DepartmentCode',
          label: 'Código Departamento',
        },
        {
          id: 'DepartmentDescription',
          label: 'Departamento',
        },
        {
          id: 'TipoRegimenDescription',
          label: 'Régimen Nómina',
        },
        {
          id: 'IDPaymentPeriod',
          label: 'Periodo Nómina',
        },
      ],
    };
  },
  methods: {
    onPrint() {
      const orderOptions = this.orderPrintOptionsSelected.map((x) => {
        return {
          PropertyName: x,
        };
      });
      this.printReceipts.apply(null, [orderOptions]);
      this.$parent.close();
    },
  },
};
</script>

<style lang="scss">
.custom-action-card {
  img {
    width: 40%;
  }
}
</style>
